import React, { useState, useEffect } from "react";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  TabPane,
  NavLink,
  TabContent,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams , Link, useNavigate } from "react-router-dom";
import { DateConfig, TimeConfig, DateTimeConfig } from "../../dev-config/global";
import defaultImage from "../../assets/images/partner-thumb.jpg";
import axios from "axios";
import NotFoundPage from "../not-found/NotFoundPage.js";
const ProductDetail = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("1");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { partner_title } = useParams();
  const store = useSelector((state) => state.products);
  const navigate = useNavigate();
  // alert(id);
  // const product = {product_id:id};
  /*const productSlides = [
    "https://swiperjs.com/demos/images/nature-1.jpg",
    "https://swiperjs.com/demos/images/nature-2.jpg",
    "https://swiperjs.com/demos/images/nature-3.jpg",
    "https://swiperjs.com/demos/images/nature-4.jpg",
    "https://swiperjs.com/demos/images/nature-5.jpg",
  ];*/
  let data = store.details_list ? store.details_list : null;
  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState(null);
  const [registrationUrl, setRegistrationUrl] = useState(null);
  const [eventImage, setEventImage] = useState([]);
  const [NotFound, setNotFoundMsg] = useState(false);

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const routeListChange = () => {
    navigate(-1);
    // Write your codes to save the category
    //history.push("my-partner-events-list");
    //navigate.goBack(); //("/my-partner-events-list");
  };

  const redirectURL = (url) => {
    return window.open(url, "_blank");
  };
  // const productInfo = () => {
  //  console.log('test',{product_id:id});
  //   return {product_id:id};
  // };

  const getCategoryname = (row) => {
    if (row.length > 0) {
      return row
        .map(function (e) {
          return e.category_name;
        })
        .join(", ");
    }
  };

  useEffect(() => {
    if (partner_title) {

      axios.get(`/partner-event/getPartnerEventByTitle/${partner_title}`)
      .then((response) => {

        if(response.data.data === null){
          setNotFoundMsg(true);
        }
        else
        {
        setTitle(response.data.data?.title);
        setDescription(response.data.data?.description);
        setRegistrationUrl(response.data.data?.registration_url);
        setStartDate(response.data.data?.start_date_time);
        setEndDate(response.data.data?.end_date_time);
        setEventImage(response.data.data?.event_image ? response.data.data?.event_image : defaultImage);
        }
        
      });

      //viewPartnerEventDetails(partner_title);
    }
  }, []);

  /*useEffect(() => {
    console.count();
    if(store?.details_list){
      console.log('if');
      console.count();
    setTitle(data?.title);
    setDescription(data?.description);
    setRegistrationUrl(data?.registration_url);
    setStartDate(data?.start_date_time);
    setEndDate(data?.end_date_time);
    setEventImage(data?.event_image ? data?.event_image : defaultImage);
    }
    else{
      console.log('else');
      console.count();
      setNotFoundMsg(true);
    }

  }, [store]);*/

    return (!NotFound) ? (
    <>
      <PageWrapper className="py-6" isGradientBg={true}>
      <Container>
        <CustomCard className="border-0 container-card">
          <CardBody className=" pt-5">
            <h1 className="mb-3">{title}</h1>
            <Row>
              <Col lg="9" md={8}>
                <div>
                  <img src={eventImage} alt="" width={"100%"} />
                  <p dangerouslySetInnerHTML={{ __html: description }} className="mt-3"></p>

                  <Button
                    color="primary"
                    size="sm"
                    tag="a"
                    target="_blank"
                    onClick={() => redirectURL(registrationUrl)}
                  >
                    More Info
                  </Button>
                </div>
              </Col>
              <Col lg="3" md={4} className="mt-4 mt-lg-0">
                <div style={{ position: "sticky", top: "120px" }}>
                  <ProductMeta className="mb-2">
                    <div className="category mb-2">
                      <span className="title mr-1">Start Date:</span>
                      <span>{DateConfig(startDate)}</span>
                    </div>
                    <div className="manufacturer mb-2">
                      <span className="title mr-1">End Date:</span>
                      <span>{DateConfig(endDate)}</span>
                    </div>
                    <div className="category mb-2">
                      <span className="title mr-1">Start Time:</span>
                      <span>{TimeConfig(startDate)}</span>
                    </div>
                    <div className="manufacturer">
                      <span className="title mr-2">End Time:</span>
                      <span>{TimeConfig(endDate)}</span>
                    </div>
                  </ProductMeta>
                  <Button
                    block
                    color="secondary"
                    size="sm"
                    onClick={() => redirectURL(registrationUrl)}
                  >
                    Attend
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </CustomCard>
      </Container>
    </PageWrapper>
    </>) : 
    (<> <NotFoundPage /> </>);
};

export default ProductDetail;

export const CustomCard = styled(Card)`
  border-radius: 30px;
  & > .card-body {
    padding: 30px 30px 0px;
  }
`;

export const ProductCardBody = styled.div`
  h4 {
    font-size: 1.4rem;
  }
`;

export const ReviewWrapper = styled.div`
  font-size: 0.856rem;
`;

export const ProductMeta = styled.div`
  font-size: 0.9rem;
  .title {
    font-weight: 600;
  }
`;

export const TabsWrapper = styled.div`
  .nav-tabs {
    border: unset;
    gap: 5px;
    a {
      font-size: 1.1rem;
      font-weight: 600;
      border-radius: 0.25rem;
      cursor: pointer;
      color: #787878;
      border: 1px solid #e2e2e2 !important;
      background-color: #f9f9f9;
      padding: 8px 16px;
      &.active {
        background-color: #dee8ff;
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }
  }
`;

export const DescriptionWrapper = styled.div`
  & div > :last-child {
    margin-bottom: 0;
  }
`;

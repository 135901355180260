import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Tooltip,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { AvForm, AvInput } from "availity-reactstrap-validation-safe";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Trash2, ChevronDown, Plus } from "react-feather";
import { createPartnerEvent } from "../../Store/Action/ProductAction.js";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import PostTeaser from "../../components/post-teaser/PostTeaser";
import {
  valSwalSuccess,
  valSwalConfirm,
  valSwalFailure,
  swalalertWithMsg,
} from "../../dev-config/global.js";
import { Editor } from "@tinymce/tinymce-react";

const AddProduct = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [body, SetBody] = useState(null);

  const [imageUpload, setImageUpload] = useState([null]);
  const shouldRedirect = true;

  const navigate = useNavigate();

  /* *** File upload function start *** */

  /* Feature image */
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  const dropImageRef = useRef();

  //Handling image
  const onDropImage = (files) => {
    const [uploadedFile] = files;
    setFeaturedImage(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsPreviewAvailable(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  //Handling image

  const updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };

  const removeImage = (e) => {
    setFeaturedImage(null);
  };

  /* *** File upload function end *** */

  const routeListChange = () => {
    // Write your codes to save the category
    //history.push("my-partner-events-list");
    navigate("/events/my-partner-events");
  };
  const dispatch = useDispatch();

  // ** Function to handle form submit
  const onSubmit = async (e, errors, values) => {
    console.log(errors, "errors");
    var validation = {
      title: "Partner Event Title",
      description: "Description",
      registration_url: "Registration URL",
      //registration_code: "Registration Code",
      start_date: "Start Date",
      start_time: "Start Time",
      end_date: "End Date",
      //end_time: "End Time",
    };
    if (errors.length > 0) {
      valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
      return false;
    } else {
      /*else if (featuredImage == null) {
      valSwalFailure("Error!", "Please Choose the Featured Image");
      return false;
    }*/
      swalalertWithMsg(true, "please wait!");
      let payload = values;
      payload.slug = payload.title.toLocaleLowerCase().trim().replaceAll(" ", "-");
      payload.description = body;
      var formDataAppend = new FormData();
      for (var key in payload) {
        formDataAppend.append(key, payload[key] ? payload[key] : "");
      }
      formDataAppend.append("event_image", featuredImage);
      formDataAppend.append("user_id", user.sub.replace("auth0|", ""));

      swalalertWithMsg(true, "Please Wait!");
      dispatch(createPartnerEvent(formDataAppend))
        .then((res) => {
          valSwalSuccess("Success", "Partner Event Updated Successfully!");
          navigate("/events/my-partner-events");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  return (user) ? (
    <>
      <PageWrapper id="page-wrapper" className="py-6" isGradientBg={true}>
      <Container>
        <FormWrapper>
          <AvForm onSubmit={onSubmit}>
            <Row>
              <Col lg="12" xl="12">
                <Card className="border-0 container-card">
                  <CardBody className=" pt-5">
                    <h1 class="text-center text-capitalize mb-3">Partner Event Request</h1>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="title" className="d-flex align-items-center">
                            Partner Event Title <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            name="title"
                            id="title"
                            placeholder="Partner Event Title"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="description" className="d-flex align-items-center">
                            Description<span className="text-danger ml-1">*</span>
                          </Label>
                          <EditorWrapper>
                            <Editor
                              apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                              theme="modern"
                              readOnly={origin == "declined"}
                              init={{
                                height: 250,
                                branding: false,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                ],
                                // toolbar:
                                //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                                toolbar:
                                  "bold italic | alignleft aligncenter alignright | bullist numlist",
                                images_upload_url: "/api/page-template/aws",
                                images_file_types: "jpg,jpeg,png,svg,webp",
                                paste_data_images: true,

                                setup: (editor) => {
                                  editor.on("init", () => {
                                    const maxWordCount = 300;
                                    const wordCountWarningThreshold = maxWordCount - 20;

                                    const checkWordCount = () => {
                                      const content = editor.getContent({
                                        format: "text",
                                      });
                                      const words = content.split(/\s+/).filter(Boolean);
                                      if (words.length > maxWordCount) {
                                        // editor.setMode('readonly');
                                        const truncatedContent = words
                                          .slice(0, maxWordCount)
                                          .join(" ");
                                        editor.setContent(truncatedContent);
                                        alert(
                                          "You have reached the maximum word count limit (300 words)."
                                        );
                                      }
                                      // else if (words.length > wordCountWarningThreshold) {
                                      //   alert('You are approaching the maximum word count limit (300 words).');
                                      // }
                                      else {
                                        //editor.setMode("design");
                                      }
                                    };
                                    editor.on("input", checkWordCount);
                                    editor.on("paste", () => {
                                      setTimeout(checkWordCount, 0);
                                    });
                                  });
                                },
                              }}
                              // value={details?.description}
                              onEditorChange={(content) => {
                                SetBody(content);
                              }}
                            />
                          </EditorWrapper>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="start_date" className="d-flex align-items-center">
                            Start Date<span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            type="date"
                            id="start_date"
                            name="start_date"
                            placeholder="Start Date"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label for="manufacturer" className="d-flex align-items-center">
                            Start Time
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            type="time"
                            id="start_time"
                            name="start_time"
                            placeholder="Start Time"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="end_date" className="d-flex align-items-center">
                            End Date
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            type="date"
                            id="end_date"
                            name="end_date"
                            placeholder="End Date"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label for="manufacturer" className="d-flex align-items-center">
                            End Time
                          </Label>
                          <AvInput
                            type="time"
                            id="end_time"
                            name="end_time"
                            placeholder="End Time"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      
                      <Col lg="6">
                        <FormGroup>
                          <Label for="registration_url" className="d-flex align-items-center">
                            Registration URL
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            type="url"
                            name="registration_url"
                            id="registration_url"
                            placeholder="http://test.com"
                            required
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <FormGroup>
                          <Label for="manufacturer" className="d-flex align-items-center">
                            Registration Code
                          </Label>
                          <AvInput
                            name="registration_code"
                            id="registration_code"
                            placeholder="Registration Code"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white">
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Featured Image
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <DropzoneWrapper className="featured-image">
                              <Dropzone
                                onDrop={onDropImage}
                                onDragEnter={() => updateBorder("over", dropImageRef)}
                                onDragLeave={() => updateBorder("leave", dropImageRef)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps({
                                      className: "drop-zone ",
                                    })}
                                    ref={dropImageRef}
                                    style={{ height: "unset" }}
                                  >
                                    <input {...getInputProps()} accept=".jpg,.jpeg,.png,.svg" />
                                    {!featuredImage ? <div>Drag or Click to Upload</div> : ""}
                                    {featuredImage ? (
                                      <div className="selected-item-wrapper w-100">
                                        <div className="selected-item">
                                          <Button className="remove-selected">
                                            <Trash2 size="14" onClick={removeImage} />
                                          </Button>

                                          <img
                                            src={isPreviewAvailable}
                                            width="100%"
                                            height=""
                                            alt=""
                                          />

                                          {/* {file && (
                                          <div className="file-name mt-1">
                                            <strong>Selected: </strong> {featuredImage.name}
                                          </div>
                                        )} */}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </DropzoneWrapper>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-3 align-items-center">
                      <Col lg="12">
                        <div
                          className="text-right d-flex flex-column flex-sm-row justify-content-end flex-wrap "
                          style={{ gap: "15px" }}
                        >
                          <PublishButton color="success" size="sm">
                            Submit for Approval
                          </PublishButton>
                          <CancelButton
                            color="danger"
                            size="sm"
                            className=""
                            onClick={() => routeListChange()}
                          >
                            Discard
                          </CancelButton>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </FormWrapper>
      </Container>
    </PageWrapper>
    </>) : (<> <PostTeaser/> </>);
};
export default AddProduct;

export const FormWrapper = styled.section`
  .container-card {
    border-radius: 30px;
    & > .card-body {
      padding: 30px 30px 0px;
    }
  }
`;

export const DropzoneWrapper = styled.div`
  /* only for featured image  */
  &.featured-image {
    img {
      max-height: 325px;
      object-fit: contain;
    }
  }
  /* /featured image */

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .remove-selected {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }

    &:hover {
      border: 1px dashed var(--primary) !important;
    }

    & > div:not(.selected-item-wrapper) {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }

  div:not(.image-selected) {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .selected-item {
    position: static;

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
`;

export const CategoryTree = styled.ul`
  list-style: none;

  li {
    text-transform: capitalize;
  }
  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;

export const PublishButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const AddNewCategory = styled.div``;

export const AddCategoryBtn = styled(Button)`
  border-width: 1px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f3f6ff;
  font-size: 14px;
`;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const WysiwygEditor = styled.div`
  .demo-editor.rdw-editor-main,
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .demo-editor.rdw-editor-main {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0px 1rem;
    min-height: 300px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0px;
  }

  .rdw-editor-wrapper:focus-within {
    border-color: #3365d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 51, 114, 0.25);
    border-radius: 0.25rem;

    .demo-editor.rdw-editor-main,
    .rdw-editor-toolbar {
      border-color: #3365d6;
    }

    .demo-editor.rdw-editor-main {
      border-top-color: #ced4da;
    }
  }
`;

export const AddGalleryButton = styled(Button)`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  border-width: 1.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorWrapper = styled.div`
  .tox-tinymce {
    border-radius: 0.25rem;
    border-color: #d8d6de;
    border-width: 1px;

    * {
      border-color: #d8d6de !important;
    }
  }

  .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 !important;
  }
`;

import React, { useState, useRef, useEffect } from "react";
import { NavbarBrand } from "reactstrap";
import Logo from "../../assets/images/coa-main-logo.svg";
import FavIcon from "../../assets/images/coa.svg";
import NavbarToggler from "reactstrap/lib/NavbarToggler";
import { Menu, X } from "react-feather";
import Topbar from "./Topbar";
import { menuItems } from "./menuItems";
import Navbar from "./components/CustomNavbar";
import styled from "styled-components";

function Header() {
  const [menuToggle, setMenuToggle] = useState(false);
  const primaryMenuRef = useRef(null);
  const [sticky, setSticky] = useState(false);
  const [scrollAnimation, setScrollAnimation] = useState(false);

  const onMenuToggle = (e) => {
    setMenuToggle(!menuToggle);
  };

  const handleScrollEvent = () => {
    //   Handle sticky for primary header

    if (window.scrollY > 67) {
      setSticky(true);
    } else if (window.scrollY < 67) {
      setSticky(false);
    }

    // Reduce padding while scrolling
    if (window.scrollY > 300) {
      setScrollAnimation(true);
    } else if (window.scrollY < 300) {
      setScrollAnimation(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [sticky]);

  return (
    <HeaderWrapper id="header">
      <Topbar />
      <div
        className={`menu-container ${sticky ? "sticky" : ""} ${scrollAnimation ? "shrink" : ""}`}
      >
        <div id="primary-menu" ref={primaryMenuRef} className="menu-wrapper">
          <NavbarBrand
            href="https://communityoncology.org/"
            target={"_self"}
            className="mr-auto ml-0 p-0"
          >
            <img
              src={Logo}
              alt="Favicon"
              className="d-inline-block d-lg-none"
              style={{ height: "50px" }}
            />
            <img src={Logo} alt="Logo" width="250px" className="d-none d-lg-inline-block" />
          </NavbarBrand>
          <NavbarToggler
            aria-controls="main-menu"
            className={`mr-0 ${menuToggle ? "active" : "disabled"}`}
            onClick={() => onMenuToggle()}
          >
            <div className="toggle-wrapper">
              <Menu color="#173372" size="18px" className="toggler-disabled" />
              <X color="#173372" size={"18px"} className="toggler-active" />
            </div>
          </NavbarToggler>
          <Navbar menuItems={menuItems} menuToggle={menuToggle} breakPoint="1200" />
        </div>
      </div>
    </HeaderWrapper>
  );
}

export default Header;

export const HeaderWrapper = styled.header`
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 9999;
  min-height: 128.01px;
  position: relative;

  .menu-container {
    position: relative;
    transition: all 300ms ease;
    z-index: 99;

    .menu-wrapper {
      background-color: #fff;
      box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.1);

      position: relative;
      transition: padding 300ms ease;
    }

    @keyframes fadeIn {
      0% {
        transform: translateY(-50px);
      }
      100% {
        transform: translateY(0);
      }
    }

    &.sticky {
      position: fixed;
      width: 100%;
      height: auto;
      top: 0;

      animation: fadeIn 300ms ease;
    }

    @media (min-width: 1200px) {
      &.shrink {
        #primary-menu {
          ul.menus > .menu-items > a,
          ul.menus > .menu-items > div > a {
            padding-top: 26px !important;
            padding-bottom: 26px !important;
          }
        }
      }
    }

    .dropdown {
      /* border-radius: 0px; */
      box-shadow: unset !important;
      li {
        /* &:not(:last-child) {
          border-bottom: 1px solid rgba(65, 128, 190, 0.13);
        } */

        a {
          padding: 8px 15px !important;
          font-size: 15px !important;
          font-weight: 500 !important;
          color: var(--primary);
          letter-spacing: 0.5px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    min-height: 97px;
  }

  #primary-menu {
    padding: 5px 20px;
    display: flex;
    align-items: center;

    .menus {
      li {
        &:last-child,
        &:nth-last-child(2) {
          .dropdown {
            right: 0px !important;
            left: unset;
          }
        }

        .dropdown {
          .dropdown {
            li {
              a {
                color: var(--primary) !important;

                &:hover {
                  color: var(--secondary) !important;
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      .menus {
        & .menu-items {
          a &:not(:last-child) {
            margin-right: 41.5px;
          }
          a {
            font-size: 15px;
            font-weight: 600;
            padding-top: 30px;
            padding-bottom: 30px;
            font-family: var(--tertiaryFont);
            letter-spacing: 0.5px;
          }
        }

        li {
          .dropdown {
            /* Second level dropdown */

            a {
              padding: 13px 20px !important;
            }

            .dropdown {
              left: auto !important;
              right: 100% !important;
            }
          }
        }
      }
    }

    @media (max-width: 1199px) {
      min-height: 60px;
      .menus {
        top: 61px;

        & > .menu-items {
          a {
            /* font-size: 17px; */
            font-weight: 600;
            letter-spacing: 0.5px;
          }
        }

        li {
          width: 100%;
          .dropdown {
            /* Second level dropdown */

            .mobile-arrow {
              justify-content: flex-end;
              /* padding-right: 20px; */

              svg {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }

    .navbar-toggler {
      position: relative;
      padding: 0px !important;
      background-color: $primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: unset;
      align-self: center;
      height: 100%;
      outline: unset;

      .toggle-wrapper {
        position: absolute;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        right: 8px;
      }

      svg {
        position: absolute;
        stroke-width: 4px;
        transition: all 300ms ease;
        opacity: 0;
        width: 25px;

        transform-origin: center center;
        opacity: 0;
      }

      &.disabled {
        .toggler-disabled {
          opacity: 1;
          transform: rotate(-360deg);
        }
      }

      &.active {
        .toggler-active {
          opacity: 1;
          transform: rotate(360deg);
        }
      }

      @media (min-width: 1201px) {
        display: none;
      }
    }
  }
`;

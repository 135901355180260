import axios from 'axios'
import authHeader from '../../../auth/Login'

// ** Get all Data
export const getAllData = () => {
  return async dispatch => {
    await axios.get('/users/coa-users', { headers: authHeader() }).then(response => {
      dispatch({
        type: 'GET_ALL_DATA',
        data: response.data
      })
    })
  }
}

// ** Get all Data
export const getAllUsers = () => {
  return async dispatch => {
    await axios.get('/users/coa-all-users', { headers: authHeader() }).then(response => {
      dispatch({
        type: 'GET_ALL_DATA_ADMIN',
        data: response.data
      })
    })
  }
}

// ** Get data on page or row change
export const getData = params => {
  return async dispatch => {
    await axios.get('/users/coa-users/list', { params }).then(response => {
      dispatch({
        type: 'GET_DATA',
        data: response.data.users,
        totalPages: response.data.total,
        params
      })
    })    
  }
}

// ** Get data on page or row change
export const getUserAdminView = (id) => {
  return async dispatch => {
    await axios.get(`/users/admin-view-users/edit/${id}`,{ headers: authHeader() }).then(response => {
      dispatch({
        type: 'GET_DATA_ADMIN',
        data: response.data,
      })
    })    
  }
}

// ** Get User
export const getUser = (id) => {
  return async dispatch => {
    await axios.get(`/user/${id}`, { headers: authHeader() })
      .then(response => {
        dispatch({
          type: 'GET_USER',
          selectedUser: response.data
        })
      })
      .catch(err => console.log(err))
  }  
}

// ** Get User From Mongo
export const getUserDB = (id) => {
  return async dispatch => {
    await axios.get(`/user/currentOrg/${id}`)
      .then(response => {
        dispatch({
          type: 'GET_USER_FROM_DB',
          selectedUser: response.data
        })
      })
      .catch(err => console.log(err))
  }  
}



// ** New User
export const createUser = formData => {
  return async dispatch => {
    
      await axios.post('/users/create', formData).then(response => {
      dispatch({
          type: 'ADD_DATA',
          data: response.data
      })
    })
  }
}

// ** New User
export const editUser = (userProfileVal) => { 
  return async dispatch => {
      await axios.post('/user/update', userProfileVal, { headers: authHeader() }).then(response => {
        var userData = JSON.parse(localStorage.getItem('userData'));
        userData['username'] = response.data.name;
        userData['last_name'] = response.data.last_name;
        localStorage.setItem('userData', JSON.stringify(userData))
      dispatch({
          type: 'UPDATE_DATA',
          data: response.data
      })
    })
  }
}

// ** Edit User
export const editUserAdmin = (userProfileAdminView) => { 
  return async dispatch => {
      await axios.post('/user/adminview/update', userProfileAdminView, { headers: authHeader() })
      .then(response => {
      dispatch({
          type: 'UPDATE_DATA_ADMIN',
          data: response.data
      })
    })
  }
}

// ** Delete User
export const deleteUser = (id) => {
  return async dispatch => {
      await axios.get(`/user/delete/${id}`).then(response => {
      dispatch({
          type: 'DELETE_DATA',
          payload: id
      })
    })
  }
}



// ** Current User
export const currentUser = (id) => {
  return async dispatch => {
    await axios.get(`/user/${id}`, { headers: authHeader() })
      .then(response => {
        dispatch({
          type: 'CURRENT_USER',
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }

  
}

// ** Set Current User
export const setCurrentUser = (id) => {
  return async dispatch => {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: null
        })
  }
}

// ** Current User
export const sendEmailConfirmation = (id) => {
  return async dispatch => {
    await axios.get(`/user/confirmation-email/${id}`)
      .then(response => {
        dispatch({
          type: 'CURRENT_REG_USER',
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
  
}

// ** Current User Empty
export const currentUserEmpty = () => {
  return async dispatch => {
    
        dispatch({
          type: 'CURRENT_USER_EMPTY',
          data: []
        })
  }
}


// Registration Scrteen Update

// ** Current Reg User
export const currentRegUser = (id) => {
  return async dispatch => {
    await axios.get(`/user/${id}`, { headers: authHeader() })
      .then(response => {
        dispatch({
          type: 'CURRENT_REG_USER',
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Set Current Reg User
export const setCurrentRegUser = (id) => {
  return async dispatch => {
        dispatch({
          type: 'SET_CURRENT_REG_USER',
          data: null
        })
  }
}

// ** Update Reg User
export const editRegUser = (userProfileVal) => { 
  return async dispatch => {
      await axios.post('/user/update', userProfileVal, { headers: authHeader() }).then(response => {
      dispatch({
          type: 'UPDATE_REG_USER',
          data: response.data
      })
    })
  }
}

//Update User Profile
export const updateUserProfile = (userProfileVal) => {
  return async dispatch => {
    await axios.post('/user/profile-update', userProfileVal, { headers: authHeader() }).then(response => {
    dispatch({
        type: 'UPDATE_REG_USER',
        data: response.data
    })
  })
}
}

export const updateUserPassword = (data) => {
  return async dispatch => {
    await axios.post(`/user-update-password`,data).then(response => {
      dispatch({
        type: 'UPDATE_USER_PASSWORD',
        data: response.data
      })
    })
  }
}



import styled from "styled-components";

export const FooterWrapper = styled.div`
  background-color: #133d8d;
  color: #fff;
  padding: 0px 15px;
  box-shadow: unset;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;

  p {
    text-align: left;
    line-height: 1.25;
  }

  a {
    color: #fff;
    transition: color 300ms ease;
    line-height: 1.25;
    &:hover {
      color: var(--link);
    }
  }

  h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 24px;
  }
  .column-1 {
    text-align: left;
  }
  .social-media {
    gap: 15px;
    padding-top: 6px;

    a {
      transition: 300ms ease;
      background-color: #12285c;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border-radius: 3px;

      &:hover {
        color: #fff;
        background-color: var(--secondary);
      }
    }
  }

  .custom-container {
    width: 100%;

    .inner-wrapper {
      gap: 50px 30px;
      justify-content: center;

      .column-1 {
        font-size: 12px;
        font-size: 12px;
        font-weight: 600;

        a {
          font-weight: 600;
        }
        max-width: 16%;
      }

      .column-2 {
        max-width: 17%;
      }

      .column-3 {
        max-width: 18%;
      }

      .column-4 {
        max-width: 20%;
      }

      .column-5 {
        max-width: 17%;
      }

      @media (max-width: 1200px) {
        justify-content: flex-start;
        .col {
          /* max-width: 100% !important; */
        }

        .column-1 {
          flex: 0 0 25%;
          max-width: 25%;
        }

        .column-2 {
          flex: 0 0 25%;
          max-width: 25%;
        }

        .column-3 {
          flex: 0 0 40%;
          max-width: 60%;
        }

        .column-4 {
          flex: 0 0 25%;
          max-width: unset;
        }

        .column-5 {
          flex: 0 0 60%;
          max-width: 60%;
        }
      }

      @media (max-width: 768px) {
        .col {
          max-width: 100%;
          flex: 1 100%;
        }
      }

      @media (min-width: 768px) {
        .column-1 p {
          max-width: 200px;
          margin-inline: auto;
        }
      }
    }
  }
`;

export const CopyRight = styled.div`
  background-color: #133d8d;
  color: #fff;
  min-height: unset;
  box-shadow: unset;
  font-size: 12px;
  padding-bottom: 6px;
  font-family: "Montserrat";

  a {
    transform: color 300ms ease;
    &:hover {
      color: #fff;
    }
  }
`;

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;

  a {
    transition: color 300ms ease;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover {
      color: var(--link);
    }
  }
`;

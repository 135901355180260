import { combineReducers } from 'redux'
import  PostReducer  from "./PostReducer"
import  users  from "../../../src/views/user/store/reducer/index.js"
import  ProductReducer  from "./ProductReducer"

// Combine Reducers
const RootReducer = combineReducers({
    posts: PostReducer,
    users: users,
    products:ProductReducer,
});

export default RootReducer;
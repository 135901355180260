import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Calendar, ExternalLink } from "react-feather";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import parse from "html-react-parser";

const HorizontalArticle = ({ target, image, title, date, excerpt, categories_slugs }) => {
  const categoryUrl = "/" + categories_slugs?.[0]?.slug;
  const subCategoryUrl = "/" + categories_slugs?.[0]?.slug + "/" + categories_slugs?.[1]?.slug;
  return (
    <HorizontalArticleWrapper>
      <Card className="border-0 article-vertical">
        <div className="item-wrapper d-flex ">
          <div className="featured-image-wrapper mr-0 mr-lg-4">
            <img
              src={image}
              alt="artilce image"
              className="rounded featured-image-thumb mb-4 mb-lg-0"
              width={"100%"}
            />
          </div>
          <div className="align-self-start right-side">
            <CardBody className="p-0 ">
              {/* <p className="meta-date mb-2 d-flex align-items-center"> */}
              {/* <Calendar size="13" className="mr-1" />  */}
              {/* {date}
              </p> */}

              <Link to={subCategoryUrl + "/" + target}>
                <h3 className="mb- weight-700 title">{title}</h3>
              </Link>

              {excerpt && <p className="excerpt">{excerpt ? parse(`${excerpt}`) : ""}</p>}

              <div className="meta d-flex align-items-center flex-wrap">
                <a target="_self" href={categoryUrl} className="category">
                  {categories_slugs &&
                  categories_slugs?.[0]?.name != undefined &&
                  categories_slugs?.[0].name != null &&
                  categories_slugs?.[0].name != ""
                    ? categories_slugs?.[0].name
                    : ""}
                </a>{" "}
                <a target="_self" href={subCategoryUrl} className="sub-category">
                  {categories_slugs &&
                  categories_slugs?.[1]?.name != undefined &&
                  categories_slugs?.[1].name != "" &&
                  categories_slugs?.[1].name != null
                    ? categories_slugs?.[1].name
                    : ""}
                </a>{" "}
                <div className="date">
                  {date
                    ? new Date(date).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    : ""}
                </div>
                <Link
                  to={subCategoryUrl + "/" + target}
                  className="text-secondary d-flex align-items-center weight-700 read-more-link"
                >
                  <ExternalLink size="14px" />
                </Link>
              </div>
            </CardBody>
          </div>
        </div>
      </Card>
    </HorizontalArticleWrapper>
  );
};

export default HorizontalArticle;

export const HorizontalArticleWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #d1d3d4;
  }

  /* &:nth-last-child(2) {
    border-bottom: unset;
    padding-bottom: 0;
  } */

  h4 {
    color: #4180be;
  }

  .featured-image-wrapper {
    flex: 0 0 220px;
  }

  .right-side {
    flex: 1;

    .meta {
      margin-top: -5px;
      gap: 10px 20px;
      .category,
      .sub-category {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: -10.3px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background-color: var(--link);
        }
      }

      .category,
      .sub-category,
      .date {
        color: #4180be;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: 0.75px;
      }
    }
  }

  .featured-image-thumb {
    transition: all 0.3s ease;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &:hover {
    .featured-image-thumb {
      transform: scale(1.02);
    }
  }

  button {
    font-size: 14px;
  }

  .meta-date {
    /* color: var(--primary); */
    /* font-weight: 800; */
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 24px;
  }

  .excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }
  }

  .read-more-link {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    .card-body {
      margin-top: -4px;
    }

    .col-lg-3 {
      min-width: 305px;
    }
  }

  @media (max-width: 991.5px) {
    padding-bottom: 35px !important;

    .item-wrapper {
      flex-direction: column;

      .featured-image-wrapper {
        flex: 1;
      }

      .title {
        display: block;
      }
    }

    .featured-image-thumb {
      max-height: unset;
      min-height: unset;
    }
  }
`;

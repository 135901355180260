import React from "react";
import { SinglePostWrapper } from "../_singlePostStyle";
import { Col, Row, Container, Button } from "reactstrap";
import SocialShare from "../../../../components/social-share/SocialShare";
import { Download, ExternalLink, FileText } from "react-feather";
import PostTeaser from "../../../../components/post-teaser/PostTeaser";

// images
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg";

import parse from "html-react-parser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HorizontalArticle from "../../../../components/horizontal-article/HorizontalArticle";

const FullWidthTemplate = (props) => {
  const { post, relatedPosts, isAuthenticated, publicView, allPost } = props;
  return post && post != undefined && post != "" ? (
    <>
      <PageTemplate id="page-wrapper" className="pb-3 pt-3 pt-lg-6 pb-lg-6">
        <Container>
          <Row className={`layout-wrapper ${post.name}`}>
            <Col lg="12" className="">
              {post && post !== undefined ? (
                <article>
                  {isAuthenticated || publicView == true ? (
                    <div className="content">{parse(`${post.body}`)}</div>
                  ) : (
                    <div id="noAuthCan">{/* {parse(`${post.short_description}`)} */}</div>
                  )}
                </article>
              ) : (
                <p>...</p>
              )}
            </Col>
          </Row>
          <hr className="bg-primary" style={{ marginBottom: "40px" }} />
          <Row>
            <div class="col-12">
              {allPost.map((data, index) => {
                return (
                  <HorizontalArticle
                    target={`${data.slug}`}
                    image={data.feature_image || placeholderImage}
                    title={data.title}
                    date={data.created_at}
                    excerpt={data.short_description}
                    categories_slugs={data.categories_slugs}
                  />
                );
              })}
            </div>
          </Row>
        </Container>
      </PageTemplate>
    </>
  ) : (
    <p>...</p>
  );
};

export default FullWidthTemplate;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 10%);
  .img-copa,
  .img-can {
    display: none !important;
  }
  hr {
    color: #d1d3d4;
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .content {
    img {
      max-width: 100%;
      height: auto;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin-top: 30px;
    }

    p + img {
      margin-bottom: 1rem;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

import styled from "styled-components";
// import { menuItems } from "../menuItems";
import MenuItems from "./MenuItems";
const Navbar = ({ isMobile, menuToggle, menuItems, breakPoint }) => {
  return (
    <MenuWrapper
      className={`menus  ${menuToggle ? "show" : "hide"}`}
      breakPoint={breakPoint}
    >
      {menuItems?.map((menu, index) => {
        const depthLevel = 0;
        return (
          <MenuItems
            items={menu}
            key={index}
            depthLevel={depthLevel}
            isMobile={isMobile}
          />
        );
      })}
    </MenuWrapper>
  );
};

export default Navbar;

export const MenuWrapper = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  margin-bottom: 0 !important;

  & > .menu-items {
    &:not(:last-child) {
      margin-right: 30px;
    }
    &:last-child {
      a {
        /* padding-right: 0; */
      }
    }

    &:nth-child(2) {
      .dropdown-submenu {
        right: 100%;
        left: auto;

        .dropdown-submenu {
          right: 0;
          left: 100%;
        }
      }
    }
  }

  .menu-items {
    position: relative;
    font-size: 15px;
    margin-bottom: 0;

    a {
      color: var(--primary) !important;
      font-family: var(--tertiaryFont) !important;
      font-weight: 600;
    }

    svg {
      color: var(--primary) !important;
    }

    &:hover > div > * {
      color: var(--secondary) !important;
    }

    .mobile-arrow {
      margin-left: 5px;
    }
  }

  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;

    font-weight: 400;
    line-height: 24px;

    &:hover {
      color: var(--secondary) !important;
    }
  }

  .menu-items a {
    text-align: left;
    padding: 5.5px 0px;
    transition: all 300ms ease;

    svg {
      transition: all 300ms ease;
    }
  }

  .dropdown {
    position: absolute;
    left: 0;
    right: auto;
    top: calc(100%);
    transition: all 300ms ease;
    font-size: 16px;
    z-index: 9999;
    min-width: max-content;
    width: 100%;
    padding: 0;
    list-style: none;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    border-radius: 0px 0px 10px 10px;
    /* overflow: hidden; */
    pointer-events: none;

    li {
      transition: all 300ms ease;

      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }

      &:hover {
        background-color: #f7f7f7;

        a {
          color: var(--secondary) !important;
        }
      }
    }

    .mobile-arrow {
      width: 20px;
    }

    a {
      padding: 8px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      transition: all 300ms ease;
      flex: 1;
      color: var(--primary);
    }
  }

  .dropdown.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0px;
  }

  @media (max-width: ${(props) => props.breakPoint}px) {
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff !important;
    top: 38px;
    flex-direction: column;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all calc(300 * 1ms) cubic-bezier(0.42, 0.01, 0.58, 1);

    &.show {
      opacity: 1;
      visibility: visible;
      z-index: 9;
    }

    li.menu-items {
      margin: 0 !important;

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      a {
        width: 100%;
        position: relative;
        padding: 8px 20px !important;
      }

      .mobile-arrow {
        position: relative;
        right: 16.5px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        cursor: pointer;
      }
    }

    .dropdown {
      box-shadow: unset;
      min-width: unset;

      &.show {
        position: relative;
      }

      li.menu-items {
        border-left: unset;
        border-right: unset;
        padding-left: 12px;
        /* width: 100vw; */
        width: 100%;

        a {
          padding-right: 40px !important;
        }

        &:last-child {
          border-bottom: unset;
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "../_singlePostStyle";
import { Col, Row, Container, Button } from "reactstrap";
import SocialShare from "../../../../components/social-share/SocialShare";
import { Download, ExternalLink, FileText } from "react-feather";
import PostTeaser from "../../../../components/post-teaser/PostTeaser";

// images
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg";

import parse from "html-react-parser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CommonTemplate = (props) => {
  const { post, relatedPosts, isAuthenticated, publicView } = props;
  const [fetchedStyle, setFetchedStyle] = useState(null);

  useEffect(() => {
    if (post?.style) {
      setFetchedStyle((post?.style).replace(/\r\n|\r|\n/g, ""));
    }
  }, [post?.style]);

  useEffect(() => {
    if (fetchedStyle) {
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = fetchedStyle;
      document.head.appendChild(styleSheet);

      return () => {
        document.head.removeChild(styleSheet);
      };
    }
  }, [fetchedStyle]);

  return post && post != undefined && post != "" ? (
    <>
      <PageTemplate id="page-wrapper" className="pb-3 pt-3 pt-lg-6 pb-lg-6">
        {/* <Helmet>
        <meta property="og:image" content="https://mycoa.communityoncology.org/static/media/REPORTS.e185e4ca86661f6f7ffe.jpg" />
        
      </Helmet> */}
        <Container>
          <Row className={` layout-wrapper ${post.name}`}>
            <Col lg="8" xl="9" className="mb-5 mb-lg-0">
              {post && post !== undefined ? (
                <article>
                  <h1 className="mb-0">{post.title}</h1>
                  <hr />
                  <div class="meta d-flex align-items-center flex-wrap">
                    {post?.date ? (
                      <div className="date mr-3">
                        {new Date(post.date).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="category mr-3">
                      {post.categories_slugs[0].name != undefined &&
                      post.categories_slugs[0].name != null &&
                      post.categories_slugs[0].name != ""
                        ? post.categories_slugs[0].name
                        : "CATEGORY"}{" "}
                      :{" "}
                      {post.categories_slugs[1].name != undefined &&
                      post.categories_slugs[1].name != "" &&
                      post.categories_slugs[1].name != null
                        ? post.categories_slugs[1].name
                        : "TAGS"}
                    </div>
                    {post.file ? (
                      <div className="download">
                        <a href={post.file} download className="d-flex align-items-center">
                          Download <Download color="red" size="12" className="ml-1" />
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <hr />
                  {isAuthenticated || publicView == true ? (
                    <div className="content mt-3 mt-lg-5">{parse(`${post.body}`)}</div>
                  ) : (
                    <div id="noAuthCan">{/* {parse(`${post.short_description}`)} */}</div>
                  )}
                </article>
              ) : (
                <p>...</p>
              )}
            </Col>
            <Col lg="4" xl="3" className="mt-lg-3">
              <SidebarWrapper>
                {post.file ? (
                  <Button
                    color="secondary"
                    size=""
                    block
                    tag="a"
                    href={post.file}
                    download
                    outline
                    className="mb-40 d-flex align-items-center justify-content-center"
                  >
                    DOWNLOAD <Download size="13" className="ml-1 stroke-width-3px" />
                  </Button>
                ) : (
                  ""
                )}

                <div className="featured-image mb-40">
                  <img
                    src={post?.feature_image ? post?.feature_image : placeholderImage}
                    alt="featured image"
                    className="w-100 rounded"
                  />
                </div>

                <div className="recommended-resources rounded">
                  <h3 className="text-center">Related</h3>
                  <ul className="list-unstyled mb-0">
                    {relatedPosts?.map((relatedPost, index) => {
                      let postUrl =
                        "/" +
                        relatedPost?.categories_slugs?.[0]?.slug +
                        "/" +
                        relatedPost?.categories_slugs?.[1]?.slug +
                        "/" +
                        relatedPost?.slug;
                      return (
                        <li key={index + relatedPost?._id}>
                          <Link to={postUrl} className="text-sm d-flex align-items-center">
                            <ExternalLink className="mr-2" size="16px" />
                            {relatedPost?.title}{" "}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </SidebarWrapper>
            </Col>
          </Row>
        </Container>
      </PageTemplate>
    </>
  ) : (
    <p>...</p>
  );
};

export default CommonTemplate;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 10%);

  hr {
    color: #d1d3d4;
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category,
  .download {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .content {
    img {
      max-width: 100%;
      height: auto;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin-top: 30px;
    }

    p + img {
      margin-bottom: 1rem;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  /* Left sidebar */

  .layout-wrapper.leftSidebar {
    flex-direction: row-reverse;
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px;

    h3 {
      font-size: 24px;
      font-weight: 700;
    }

    svg {
      stroke-width: 2.6px;
    }

    ul {
      li:not(:last-child) {
        margin-bottom: 20px;
      }

      li {
        svg {
          min-width: 16px;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }

  h3 {
    margin-bottom: 15px;
  }
`;

import styled from "styled-components";

export const TitleWrapper = styled.div`
  background-color: var(--primary);
  padding-bottom: 59px;
  color: #fff;
  .breadcrumb {
    padding: 0;
    background-color: unset;
    font-size: 14px;
    margin-bottom: 0;

    a {
      color: #c6c6c6;
    }
    .active {
      color: #fff;
    }
  }
`;

export const PageWrapper = styled.div`
  background: ${(props) =>
    props.isGradientBg
      ? "linear-gradient(rgb(213, 229, 239) 0%, rgba(213, 229, 239, 0) 40%)"
      : "#f4f5f5"};
  /* position: ${(props) => (props.isGradientBg ? "relative" : "inherit")};
  top: ${(props) => (props.isGradientBg ? "-2px" : "inherit")}; */

  .card {
    h6 {
      font-size: 16px;
    }
  }
`;

import React from "react";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
//import { BrowserRouter as Router,  Routes,  Route, Switch } from "react-router-dom";
import "./assets/scss/styles.scss";
import Loading from "./components/Loading";
import Home from "./views/home/Home";
import Profile from "./views/user/edit/index.js";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import CommentLetters from "./views/article/category/CommentLetters";
import Post1 from "./views/article/single/post1";
import PostTemplate from "./views/article/single/PostTemplate";
import Post3 from "./views/article/single/post3";
import Post4 from "./views/article/single/post4";
import PageView from "./views/article/page-view/index.js";

import PrimaryHeader from "./components/header/Header";
import PrimaryFooter from "./components/footer/Footer";
import Register from "./views/auth/Register";
import Post from "./views/post/post.js";

// Page templates
import RightSidebar2 from "./views/article/page-templates/RightSidebar2";
import ResourceAtBottom from "./views/article/page-templates/ResourcesAtBottom";
import VideoWithContent from "./views/article/page-templates/VideoWithContent";
import VideoWithMinimalContent from "./views/article/page-templates/VideoWithMinimalContent";

// Pages
import AboutPage from "./views/about/What-is-community-oncology";
import AccessDenied from "./views/access-denied/AccessDenied";
import NotFoundPage from "./views/not-found/NotFoundPage";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Login from "./views/auth/Login";

import { Helmet } from "react-helmet";

//partner events
import AllPartnerEvents from "./views/partner-events/PartnerEventList";
import PartnerEventDetail from "./views/partner-events/PartnerEventDetail";
import MyPartnerEventList from "./views/partner-events/MyPartnerEventList";
import AddPartnerEvent from "./views/partner-events/AddPartnerEvent";
import EditPartnerEvent from "./views/partner-events/EditPartnerEvent";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Router history={history}>
        <div id="app">
          <PrimaryHeader />
          <>
            <Routes>
              {/* Page templates */}
              <Route path="/right-sidebar-2" exact element={<RightSidebar2 />} />
              <Route path="/resource-at-bottom" exact component={ResourceAtBottom} />
              <Route path="/video-with-content" exact element={<VideoWithContent />} />
              <Route
                path="/video-with-minimal-content"
                exact
                element={<VideoWithMinimalContent />}
              />

              <Route path="/" exact element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              {/* <Route path="/resources" component={ExternalApi} /> */}
              <Route path="/comment-letters" element={<CommentLetters />} />
              <Route path="/post-1" exact element={<Post1 />} />
              <Route path="/post-3" exact element={<Post3 />} />
              <Route path="/post-4" exact element={<Post4 />} />
              <Route path="/about/what-is-community-oncology" exact element={<AboutPage />} />

              <Route path="/access-denied" element={<AccessDenied />} />
              <Route path="/page-not-found" element={<NotFoundPage />} />

              {/* Changed Routes */}
              <Route path="/:category/:subcategory/:slug" exact element={<PageView />} />
              <Route path="/:category/:slug" element={<Post />} />
              <Route path="/:slug" element={<Post />} />

              {/* partner-events routes*/}
              <Route path="/events/partner-events" exact element={<AllPartnerEvents/>} />
              <Route path="/events/partner-events/:partner_title" exact element={<PartnerEventDetail/>} />
              {/*<Route path="/events/view-partner-events" exact element={<PartnerEventDetail/>} />*/}
              <Route path="/events/my-partner-events" exact element={<MyPartnerEventList/>} />
              <Route path="/events/partner-event-request" exact element={<AddPartnerEvent/>} />
              <Route path="/events/edit-partner-event-request" exact element={<EditPartnerEvent/>} />

            </Routes>
          </>
          <PrimaryFooter />
        </div>
      </Router>
    </>
  );
};

export default App;

import React, { useState, useEffect } from "react";
// ** React Imports
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormGroup,
  //Pagination,
  //PaginationItem,
  //PaginationLink,
  Row,
} from "reactstrap";
import { PageWrapper, TitleWrapper } from "../../assets/styled-components/components";
import styled from "styled-components";
import { Input } from "reactstrap";
import { ExternalLink, Search, Star } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { listPartnerEvents } from "../../Store/Action/ProductAction.js";
import Pagination from "./components/Pagination";
import { PaginatedList } from "react-paginated-list";
import { forEach } from "lodash";
import Select from "react-select";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
const ProductListPage = () => {
  const dispatch = useDispatch();
  // const [rows, setRows] = useState([])
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [sort, setSort] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [selectOption, setSearchOption] = useState(null);
  const store = useSelector((state) => state.products.list);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listPartnerEvents());
  }, [dispatch]);

  useEffect(() => {
    // console.log('searchuseeff',searchQuery);
    getProductList();
  }, [searchQuery, searchFilter, selectOption]);

  const [productList, setProductList] = useState(store);
  // console.log('store',store);

  // setProductList(store);
  // console.log('productList',productList);
  // const [productAllList, setProductAllList] = useState(store);

  useEffect(() => {
    setProductList(store);
    if (store != null) {
      setProductCount(store.length);
    }
  }, [store]);

  const getProductList = () => {
    if (store != null) {
      let allProduct = store;
      if (searchFilter.length) {
        allProduct = allProduct.filter(function (obj) {
          if (obj["product_category"] != undefined && obj["product_category"].length) {
            let a = obj["product_category"].filter((value) => searchFilter.includes(value));
            return a.length > 0;
          } else {
            return false;
          }
        });
      }

      if (searchQuery) {
        allProduct = allProduct.filter(function (obj) {
          return (
            (obj["title"] ? obj["title"] : "").toLowerCase().includes(searchQuery.toLowerCase()) ||
            (obj["description"] ? obj["description"] : "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        });
      }
      setProductCount(allProduct.length);
      setProductList(allProduct);
    }
  };

  //Search list of objects
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleSort = (event) => {
    alert(event.target.value);
    setSort(event.target.value);
  };
  const options = [
    // { value: "Avg Rating", label: "Avg Rating" },
    { value: 1, label: "Rating Low to High" },
    { value: 2, label: "Rating High to Low" },
  ];

  const filterOption = (option) => {
    setSearchFilter(option);
  };

  const routeChange = (slug) => {
    window.location.href = "/events/partner-events/" + slug;

    /*return navigate({
      pathname: "/partner-event-detail",
      search: "?id=" + id,
      state: { fromPopup: true },
    });*/
  };

  if (selectOption) {
    let allProduct = store;

    var sortType = "";
    var sortByField = "";

    if (selectOption == "new-to-old") {
      sortByField = "created_date";
      sortType = "ascending";
    } else if (selectOption == "old-to-new") {
      sortByField = "created_date";
      sortType = "descending";
    }

    allProduct = sortFunc(allProduct, sortType, sortByField);
  }

  function sortFunc(results, sortType, sortByField) {
    if (sortType === "ascending") {
      results.sort((a, b) => (a[sortByField] < b[sortByField] ? -1 : 1));
    } else if (sortType === "descending") {
      results.sort((a, b) => (b[sortByField] > a[sortByField] ? 1 : -1));
    }
    return results;
  }

  const handleSortBy = (event) => {
    setSearchOption(event.value);
  };

    const routeListChange = () => {
      window.location.href = "/events/partner-event-request";
    };

  const optionstwo = [
    { value: "new-to-old", label: "Latest" },
    { value: "old-to-new", label: "Oldest" },
  ];

  return (
    store != [] && (
      <PageWrapper id="page-wrapper" className="py-6" isGradientBg={true}>
        <ProductListWrapper>
          <Container>
            <Card className="border-0 container-card">
              <CardBody className=" pt-5">
                <h1 class="text-center text-capitalize mb-3">Partner Events</h1>

                <Row>
                  <Col md="12" lg="12" className=" mt-md-0">
                    <div style={{ maxWidth: "698px" }} className="mx-auto">
                      <SearchWrapper>
                        <Input
                          type="text"
                          className="search"
                          id="search"
                          placeholder="Search"
                          size={"lg"}
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                        <span className="icon-wrapper">
                          <Search size={"18px"} />
                        </span>
                      </SearchWrapper>

                      <div className="d-flex-column d-sm-flex justify-content-between align-items-start">
                        <span className="text-warning mb-2 d-block d-sm-inline mb-sm-0">
                          {productCount} results found
                        </span>
                        <div
                          className="d-flex flex-column flex-sm-row justify-content-between "
                          style={{ gap: "15px" }}>
                        {user && (
                          <Button
                            tag={Link}
                            color="secondary"
                            size="small"
                            className="px-2 py-1 d-flex align-items-center justify-content-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              minHeight: "36px",
                            }}
                            onClick={() => routeListChange()}>
                            Partner Event Request
                          </Button>
                        )}
                          <FormGroup className="mb-0">
                            <Select
                              options={optionstwo}
                              placeholder="Sort By"
                              style={{ minWidth: "150px" }}
                              onChange={handleSortBy}
                            />

                            {/*<Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            style={{ minWidth: "150px" }}>
                            <option>Latest</option>
                            <option>Oldest</option>
                          </Input>*/}
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <hr class="mb-5 mt-5 border-primary"></hr>

                    {productList != null && (
                      <PaginatedList
                        list={productList}
                        nextText="Next"
                        prevText="Previous"
                        itemsPerPage={5}
                        renderList={(list) => (
                          <>
                            {list.map((row, id) => {
                              return (
                                <ProductListCard key={id}>
                                  <Card className="border-0">
                                    <CardBody className="p-0">
                                      <div className="product-image">
                                        <img
                                          src={row.event_image}
                                          alt=""
                                          width={"100%"}
                                          onClick={() => routeChange(row.slug)}
                                        />
                                      </div>
                                      <div className="description">
                                        <h3
                                          onClick={() => routeChange(row.slug)}
                                          className="cursor-pointer"
                                        >
                                          {row.title}
                                        </h3>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: row.description,
                                          }}
                                        ></p>
                                        <MetaWrapper className="mt-2">
                                          <Link
                                            onClick={() => routeChange(row.slug)}
                                            color="primary"
                                            size="sm"
                                            className="view-product text-uppercase d-flex align-items-center"
                                          >
                                            View Partner Event
                                            <ExternalLink className="ml-1" size={"12"} />
                                          </Link>
                                        </MetaWrapper>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </ProductListCard>
                              );
                            })}
                          </>
                        )}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </ProductListWrapper>
      </PageWrapper>
    )
  );
};

export default ProductListPage;

export const ProductListWrapper = styled.main`
  .container-card {
    border-radius: 30px;
    & > .card-body {
      padding: 30px 30px 0px;
    }
  }

  .pagination {
    margin-top: 25px;
    margin-bottom: 0;
    li {
      margin-bottom: 0;
      line-height: 1.2;
      transition: background 300ms ease;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;

  input {
    padding-right: 48px;
    border-color: var(--primary);
    border-width: 1.5px;
  }

  .icon-wrapper {
    background-color: var(--primary);
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    padding: 10px 15px;
    color: #fff;
    overflow: hidden;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    cursor: pointer;
  }
`;

export const ProductListCard = styled.section`
  &:hover {
    .product-image {
      img {
        transform: scale(1.05);
      }
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgb(231, 231, 231);
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .card-body {
    display: grid;
    grid-template-columns: 250px 2fr;
    gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;

      .product-image {
        img {
          height: unset;
        }
      }
    }
  }

  .product-image {
    position: relative;

    img {
      min-width: 250px;
      width: 100%;
      transition: all 0.3s ease 0s;
      aspect-ratio: 16 / 10;
      object-fit: cover;
      cursor: pointer;
      border-radius: 10px !important;
      transform: all 300ms ease;
    }
  }

  .description {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }
  }
`;

export const MetaWrapper = styled.div`
  a {
    color: var(--link);
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.75px;
  }
`;

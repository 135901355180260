import axios from 'axios'

export const getAllPosts = () =>{
    return async dispatch => {
        await axios.get(`/page-template/list/coa`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}

export const getPostSlug = () =>{
    return async dispatch => {
        await axios.get(`/page-template/list/coa`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}

/* 
    Get All post by slug
    URL : category/slug/postname
    api : api/page-template/list
*/
export const getPostBySlug = (data) => {
    return async dispatch => {
        await axios.post(`/page-template/list`,data)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}
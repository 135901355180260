import axios from "axios";
export const createPartnerEvent = (data) => {
  return async (dispatch) => {
    await axios.post(`/partner-event/postData`, data).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const updatePartnerEvent = (data) => {
  return async (dispatch) => {
    await axios.post(`/partner-event/updateData`, data).then((response) => {
      dispatch({
        type: "EDIT_PARTNER_EVENT",
        data: response.data.data,
      });
    });
  };
};

export const listPartnerEvents = () => {
  return async (dispatch) => {
    await axios.get(`/partner-event/list`).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const listMyPartnerEvents = (userID) => {
  return async (dispatch) => {
    await axios.get(`/partner-event/mylist/${userID}`).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const showPartnerEventDetails = (id) => {
  return async (dispatch) => {
    await axios
      .get(`/partner-event/getPartnerEventDetails/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_EVENT_DETAILS",
          data: response.data && response.data.data ? response.data.data : [],
        });
      });
  };
};

export const viewPartnerEventDetails = (title) => {
  return async (dispatch) => {
    await axios
      .get(`/partner-event/getPartnerEventByTitle/${title}`)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_EVENT_TITLE_DETAILS",
          data: response.data && response.data.data ? response.data.data : [],
        });
      });
  };
};

export const updateProductTemplate = (data) =>{
    
    return async dispatch => {
        await axios.post(`/product/updateData`,data)
            .then(response =>  {
                dispatch({
                    type: 'EDIT_PRODUCT',
                    data: response.data.data
                })
            });
    }
}

export const listProductCategory = () =>{
    return async dispatch => {
        await axios.get(`/product/category`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_CATEGORY',
                    data: response.data.data
                })
            });
    }
}


export const createCategoryTemplate = (data) =>{
    return async dispatch => {
       // var parameters = data.split('/');
        await axios.post(`/product/categoryData`,data)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_CATEGORY',
                    data: response.data
                })
            });
    }
}
// ** Get Email Data
export const showProductDetails = (id) => {
  return async dispatch => {
    await axios.get(`/product/getProductdetails/${id}`)
      .then(response =>  {
                dispatch({
                    type: 'GET_PRODUCT_DETAILS',
                    data: (response.data && response.data.data ? response.data.data : [])
                })
            });
  }
}
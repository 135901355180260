const initialState = {
  list: [],
  edit: [],
  category: [],
  details: null,
  details_list:null,
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PARTNER_EVENTS":
      return { ...state, list: action.data };
    case "EDIT_PARTNER_EVENT":
      return { ...state, edit: action.data };
    case 'GET_ALL_CATEGORY':
      return { ...state, category: action.data };
    case 'GET_PRODUCT_DETAILS':
        return { ...state, details: action.data };
    case "GET_PARTNER_EVENT_DETAILS":
      return { ...state, details: action.data };
    case "GET_PARTNER_EVENT_TITLE_DETAILS":
      return { ...state, details_list: action.data };
    default:
      return state;
  }
};
export default ProductReducer;

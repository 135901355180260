// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA,6CAA6C;AAC7C;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".next-steps .fa-link {\n  margin-right: 5px;\n}\n\n/* Fix for use only flexbox in content area */\n.next-steps .row {\n  margin-bottom: 0;\n}\n\n.next-steps .col-md-5 {\n  margin-bottom: 3rem;\n}\n\n@media (max-width: 768px) {\n  .next-steps .col-md-5 {\n    margin-bottom: 0;\n  }\n}\n\n.spinner {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n  background-color: white;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.result-block-container .result-block {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
